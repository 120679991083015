<template>
  <v-img v-if="namespace" id="hero" position="center bottom" height="100vh" :src="`/images/launch.${webpAvailable ? 'webp' : 'jpg'}`" class="min-height-800 white--text"
         gradient="to top, rgba(17,17,34, .6), rgba(26, 39, 68, .6)">
    <v-container fill-height style="max-width: 1200px;">
      <v-row align="start" align-content="center" justify="start" class="pt-5 px-3 text-row">
        <v-col>
          <v-slide-y-reverse-transition>
            <v-sheet v-show="showText" color="transparent" dark class="pa-3" max-width="500">

              <!-- Header title -->
              <h1 class="mb-4" :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'">
                {{ $t('SW_HERO_HEADER') }}
              </h1>

              <!-- Sub header -->
              <p class="title font-weight-light">
              <span class="hero-highlighted">
                {{ $t('SW_HERO_SUBHEADER') }}
              </span>
              </p>

              <div class="py-2"/>

              <!-- Action buttons -->
              <v-row class="ma-0" justify="start">
                <v-btn class="my-2" color="primary" href="#what" large rounded @click.prevent="$vuetify.goTo('#what')">
                  {{ $t('SW_MORE_INFO') }}
                  <i class="icon-arrow-down ml-2"></i>
                </v-btn>
              </v-row>
            </v-sheet>
          </v-slide-y-reverse-transition>
        </v-col>
        <v-col class="hidden-sm-and-down">
          <v-slide-y-reverse-transition>
          <v-img src="/images/screens.png" class="ml-5" v-show="showText" max-width="450" contain></v-img>
          </v-slide-y-reverse-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data () {
    return {
      showText: false
    }
  },

  mounted () {
    setTimeout(() => { this.showText = true }, 400)
  },

  computed: {
    namespace () {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss">
  .min-height-800 {
    min-height: 800px;
  }

  @media (max-height: 600px) {
    .text-row {
      margin-top: calc(50px + 5vh);
    }
  }
</style>
