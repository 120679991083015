<template>
  <section id="home" class="pa-0">
    <hero/>
    <what/>
    <why/>
    <who/>
    <tech/>
    <contact/>
    <google-map/>
  </section>
</template>

<script>
import Hero from 'components/Hero'
import What from 'components/What'
import Why from 'components/Why'
import Who from 'components/Who'
import Tech from 'components/Tech'
import Contact from 'components/Contact'
import GoogleMap from 'components/GoogleMap'

export default {
  metaInfo () {
    return {
      title: ''
    }
  },

  components: { Hero, What, Why, Who, Tech, Contact, GoogleMap }
}
</script>
