import Vue from 'vue'
import Router from 'vue-router'
import Home from 'pages/Home'
import config from 'config'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: { name: 'Home', params: { language: 'en' } } },
    { path: '/:language', name: 'Home', component: Home },
    { path: '*', redirect: { name: 'Home', params: { language: 'en' } } }
  ]
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (config.googleAnalytics) {
  Vue.use(require('vue-analytics').default, {
    id: config.googleAnalytics,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router
