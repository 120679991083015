module.exports = {
  web_url: 'https://www.edpack.org',
  name: 'Edpack',
  description: 'Building successful apps in or around education requires a huge amount of time and investment. With Edpack, not anymore.',
  keywords: 'edpack, education npm package, edtech boilerplate, educational boilerplate, educational app, edtech nederland, edtech rotterdam, edtech, education rotterdam',
  siteTitle: 'Edpack - Build modern apps in & around education in no time.',

  business: {
    name: 'Shareworks Solutions BV',
    shortName: 'Shareworks',
    mail: 'info@shareworks.nl',
    postAddress: '3033 BH Rotterdam',
    streetAddress: 'Schiekade 105',
    country: 'Netherlands',
    url: 'https://www.shareworks.nl'
  },

  // Language
  languages: ['en', 'nl'],
  defaultLanguage: 'en',
  locale: 'en',

  // 3rd party keys
  googleMapsKey: 'AIzaSyCuYvBo76_SZIAcmov00uf7KIM1DSH8D7A',
  googleAnalytics: 'UA-39220612-13'
}
