<template>
  <section id="who">
    <section-header :header="$t('SW_WHO')" :sub-header="$t('SW_WHO_SUBHEADER')" :text="$t('SW_WHO_HEADERTEXT')" />

    <v-responsive max-width="600px" height="200" class="mx-auto">
      <v-carousel delimiter-icon="icon-circle" v-model="model" height="100%" light hide-delimiter-background :show-arrows="false" cycle interval="3000">
        <v-carousel-item v-for="(project, i) in projects" :key="i" >
          <v-sheet height="100%" tile>
            <v-row align="center" justify="center">
              <a :href="project.url" rel="noopener noreferrer" target="_blank">
                <v-img :src="project.img" class="mt-3" max-width="300px" height="60px" contain></v-img>
              </a>
            </v-row>
            <p class="subtitle-1 mt-5 text-center">In collaboration with {{ project.school }}</p>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-responsive>
  </section>
</template>

<script>
export default {
  data () {
    return {
      projects: [
        { product: 'Buddycheck', school: 'Vrije Universiteit Amsterdam', img: '/images/buddycheck.png', url: 'https://www.buddycheck.io' },
        { product: 'Comproved', school: 'Universiteit Antwerpen', img: '/images/comproved.png', url: 'https://www.comproved.com' },
        { product: 'PeerVue', school: 'Fontys Hogescholen', img: '/images/peervue.png', url: 'https://www.peervue.nl' },
        { product: 'Eduapp', school: 'Eduapp BV', img: '/images/eduapp.png', url: 'https://www.eduapp.nl' }
      ],
      model: 0
    }
  }
}
</script>
