<template>
  <section id="tech" class="grey lighten-3 px-3">
    <section-header :header="$t('SW_TECH')" :sub-header="$t('SW_TECH_SUBHEADER')" />

    <!-- List of features -->
    <v-container :pt-5="$vuetify.breakpoint.mdAndUp" px-0 pb-0 style="max-width: 1200px;">
      <v-row>
        <v-col v-for="(feature, i) in features" class="tech invisible" v-observe-visibility="{callback: visibilityChanged, throttle: 100, once: true}" :key="i" cols="12" md="6">
          <v-row class="pa-0 ma-0 text-center text-sm-left" justify="center" tag="section">

            <!-- Feature icon -->
            <v-col>
              <i :class="feature.icon" class="font-56" :style="{'color': feature.color}"></i>
            </v-col>

            <!-- Feature text -->
            <v-col cols="12" md="10">
              <h3 class="title font-weight-regular mb-3 mt-2" v-text="$t(feature.header)"/>
              <p class="body-1 font-weight-light" v-text="$t(feature.text)"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data () {
    return {
      features: [
        {
          icon: 'icon-server-network',
          color: 'blue-grey',
          header: 'SW_TECH_FEATURE_1_HEADER',
          text: 'SW_TECH_FEATURE_1_TEXT'
        },
        {
          icon: 'icon-database',
          color: 'green',
          header: 'SW_TECH_FEATURE_2_HEADER',
          text: 'SW_TECH_FEATURE_2_TEXT'
        },
        {
          icon: 'icon-monitor',
          color: 'indigo',
          header: 'SW_TECH_FEATURE_3_HEADER',
          text: 'SW_TECH_FEATURE_3_TEXT'
        },
        {
          icon: 'icon-rotate-orbit',
          color: 'purple',
          header: 'SW_TECH_FEATURE_4_HEADER',
          text: 'SW_TECH_FEATURE_4_TEXT'
        },
        {
          icon: 'icon-puzzle',
          color: 'red',
          header: 'SW_TECH_FEATURE_5_HEADER',
          text: 'SW_TECH_FEATURE_5_TEXT'
        },
        {
          icon: 'icon-code-braces-box',
          color: 'brown',
          header: 'SW_TECH_FEATURE_6_HEADER',
          text: 'SW_TECH_FEATURE_6_TEXT'
        },
        {
          icon: 'icon-cloud-lock',
          color: 'purple',
          header: 'SW_TECH_FEATURE_7_HEADER',
          text: 'SW_TECH_FEATURE_7_TEXT'
        },
        {
          icon: 'icon-check-decagram',
          color: 'lime',
          header: 'SW_TECH_FEATURE_8_HEADER',
          text: 'SW_TECH_FEATURE_8_TEXT'
        },
        {
          icon: 'icon-ab-testing',
          color: 'blue',
          header: 'SW_TECH_FEATURE_9_HEADER',
          text: 'SW_TECH_FEATURE_9_TEXT'
        },
        {
          icon: 'icon-face-agent',
          color: 'amber',
          header: 'SW_TECH_FEATURE_10_HEADER',
          text: 'SW_TECH_FEATURE_10_TEXT'
        }
      ]
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      const { target } = entry

      if (isVisible) {
        target.classList.remove('invisible')
        target.classList.add('visible')
      }
    }
  }
}
</script>
<style lang="scss">
  @import "src/styles/Tech.scss";
</style>
