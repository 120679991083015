<template>
  <v-app-bar v-scroll="onScroll" :color="!isScrolling ? 'transparent' : 'rgba(17,17,34,0.9)'" short
             :flat="!isScrolling" app elevate-on-scroll dark>
    <!-- Logo -->
    <a href="#" id="logo" @click="toTop">
      <v-img alt="Logo" contain class="ml-2" src="/images/logo-white.png"/>
    </a>

    <v-spacer/>

    <v-toolbar-items>
      <!-- Contact link -->
      <v-btn href="#try-it" class="hidden-xs-only" text @click.prevent="$vuetify.goTo('#contact')">
        {{ $t('SW_CONTACT') }}
      </v-btn>

      <!-- Select language -->
      <v-btn-toggle v-if="canSwitch" mandatory v-model="selectedLanguage" tile color="blue accent-3" group>
        <v-btn v-for="language in languages" :key="language" :value="language">{{ language.toUpperCase() }}</v-btn>
      </v-btn-toggle>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import config from 'config'

export default {
  data () {
    return {
      isScrolling: false,
      selectedLanguage: this.$route.params.language || config.defaultLanguage,
      canSwitch: config.languages && config.languages.length > 1,
      languages: config.languages,
      IsMobile: window.innerWidth < 768
    }
  },

  watch: {
    selectedLanguage (newLanguage) {
      if (!newLanguage) return
      this.$router.replace({ params: { language: newLanguage } })
    }
  },

  mounted () {
    this.onScroll()
  },

  methods: {
    onScroll () {
      this.isScrolling = (window.pageYOffset ||
        document.documentElement.scrollTop || 0) > 25

      const minWidth = 80
      const maxWidth = this.IsMobile ? 150 : 300
      const paddingTopMin = 0
      const paddingTopMax = this.IsMobile ? 100 : 200
      const paddingTopCoef = this.IsMobile ? 0.8 : 1.1

      const currentWidth = maxWidth - +window.pageYOffset
      const currentTopMargin = paddingTopMax - (+window.pageYOffset / paddingTopCoef)

      window.logo.style.maxWidth = currentWidth > minWidth ? `${currentWidth}px` : `${minWidth}px`
      window.logo.style.paddingTop = currentTopMargin > paddingTopMin ? `${currentTopMargin}px` : `${paddingTopMin}px`
    },

    toTop (e) {
      e.preventDefault()
      this.$vuetify.goTo('#hero')
    }
  }
}
</script>

<style lang="scss">
  .v-app-bar > .v-toolbar__content {
    max-width: 1200px;
    margin: 0 auto;
  }
</style>

