<template>
  <v-app>
    <app-bar/>
    <v-main class="white pt-0">
      <router-view :key="resetKey" />
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
import config from 'config'
import AppBar from 'components/AppBar'
import AppFooter from 'components/AppFooter'
import { mapState } from 'vuex'

export default {
  components: { AppBar, AppFooter },

  metaInfo () {
    return {
      titleTemplate: config.siteTitle
    }
  },

  data () {
    return {
      resetKey: 1
    }
  },

  computed: mapState(['language']),

  watch: {
    language (language) {
      if (language) this.resetKey++
    }
  }
}
</script>

<style lang="scss">
  section {
    padding: 64px 16px;
  }

  @media screen and (max-width: 640px) {
    section {
      padding: 28px 16px;
    }
  }
</style>
