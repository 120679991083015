<template>
  <div id="googleMap" v-intersect="{handler: activateMap, options: { rootMargin: '200px' } }" class="map-container">
    <gmap-map v-if="isActivated" :center="centerMap" :options="mapOptions" :zoom="7" map-type-id="terrain">
      <gmap-info-window :options="infoOptions" :position="position" :opened="infoWinOpen"
                        @closeclick="infoWinOpen=false">
        <span v-html="infoContent"></span>
      </gmap-info-window>
      <gmap-marker :position="position" :clickable="false"></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import config from 'config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, { load: { key: config.googleMapsKey } })

export default {
  data () {
    return {
      isActivated: false,
      mapOptions: { scrollwheel: false, mapTypeControl: false },
      position: { lat: 51.9296064, lng: 4.4742492 },
      centerMap: { lat: 51.93410531, lng: 4.4742492 },
      infoWinOpen: true,
      infoOptions: { pixelOffset: { width: 0, height: -38 } },
      business: config.business
    }
  },

  computed: {
    infoContent () {
      return '<strong>' + this.business.name + '</strong><address>' + this.business.streetAddress + '<br>' + this.business.postAddress + '<br>' + this.business.country + '</address><br><a href="http://goo.gl/maps/SQlrh" target="_blank"><strong>Get directions</strong></a></p>'
    }
  },

  methods: {
    activateMap (entries, observer) {
      if (this.isActivated) return
      this.isActivated = entries[0].isIntersecting
    }
  }
}
</script>

<style lang="scss">
  .map-container,
  .vue-map-container {
    width: 100%;
    height: 60vh;
    min-height: 330px;
  }
</style>
