import router from '../router'
import scrollTop from 'simple-scrolltop'
import { loadLanguages } from 'utils/load-languages'
import Language from './language'

export default {
  install (Vue, options) {
    router.afterEach((to, from) => {
      // Scroll to top for all pages
      if (from.name && from.name !== to.name) scrollTop(0)

      // Check language
      if (to.params.language && Language.i18n.locale !== to.params.language) loadLanguages(Language.i18n, to.params.language)
    })
  }
}
