import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const SET_LANGUAGE = 'SET_LANGUAGE'

const state = {
  language: 'en'
}

const mutations = {
  [SET_LANGUAGE] (state, data) {
    state.language = data.payload
  }
}

const actions = {
  setLanguage ({ commit }, payload) {
    commit({ type: SET_LANGUAGE, payload })
  }
}

const store = new Vuex.Store({
  state,
  actions,
  mutations
})

export default store
