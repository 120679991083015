<template>
  <section id="why" class="grey lighten-3 px-3">
    <section-header :header="$t('SW_WHY')" :sub-header="$t('SW_WHY_SUBHEADER')" :text="$t('SW_WHY_HEADERTEXT')" />

    <v-row align="center" justify="center">
      <v-img src="/images/box-launch.png" height="140" contain></v-img>
    </v-row>
  </section>
</template>

<script>
export default {
  data () {
    return {
      features: [
        {
          icon: 'icon-account-group',
          color: 'blue-grey',
          header: this.$i18n.t('SW_FEATURE_1_HEADER')
        },
        {
          icon: 'icon-domain',
          color: 'green lighten-2',
          header: this.$i18n.t('SW_FEATURE_2_HEADER')
        },
        {
          icon: 'icon-school',
          color: 'indigo',
          header: this.$i18n.t('SW_FEATURE_3_HEADER')
        },
        {
          icon: 'icon-lock',
          color: 'teal darken-1',
          header: this.$i18n.t('SW_FEATURE_4_HEADER')
        },
        {
          icon: 'icon-security',
          color: 'orange',
          header: this.$i18n.t('SW_FEATURE_5_HEADER')
        },
        {
          icon: 'icon-puzzle',
          color: 'red accent-4',
          header: this.$i18n.t('SW_FEATURE_6_HEADER')
        }
      ]
    }
  }
}
</script>
