<template>
  <section id="what">
    <section-header :header="$t('SW_WHAT')" :sub-header="$t('SW_WHAT_SUBHEADER')" :text="$t('SW_WHAT_HEADERTEXT')" />

    <div class="text-center caption">
      * {{ $t('SW_WHAT_FOOTNOTE') }}
    </div>

    <!-- List of features -->
    <v-container v-observe-visibility="{callback: visibilityChanged, throttle: 200, once: true}" :pt-5="$vuetify.breakpoint.mdAndUp" px-0 pb-0 class="mx-auto" style="max-width: 550px;">
      <v-row :class="showFeatures ? 'visible' : 'hidden'">
        <v-col v-for="(feature, i) in features" class="feature" :class="`feature_${i}`" :key="i" cols="6" sm="4">
            <!-- Feature icon -->
          <div class="text-center">
            <i :class="feature.icon" class="font-54" :style="{'color': feature.color}"></i>
            <h3 class="title font-weight-regular mb-3 mt-2" v-text="feature.header"/>
          </div>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-img src="/images/edpack-package.png" class="mt-3" height="130" contain></v-img>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data () {
    return {
      showFeatures: false,
      features: [
        {
          icon: 'icon-account-group',
          color: 'blue-grey',
          header: this.$i18n.t('SW_FEATURE_1_HEADER')
        },
        {
          icon: 'icon-domain',
          color: 'green',
          header: this.$i18n.t('SW_FEATURE_2_HEADER')
        },
        {
          icon: 'icon-school',
          color: 'indigo',
          header: this.$i18n.t('SW_FEATURE_3_HEADER')
        },
        {
          icon: 'icon-lock',
          color: 'teal',
          header: this.$i18n.t('SW_FEATURE_4_HEADER')
        },
        {
          icon: 'icon-security',
          color: 'orange',
          header: this.$i18n.t('SW_FEATURE_5_HEADER')
        },
        {
          icon: 'icon-puzzle',
          color: 'red',
          header: this.$i18n.t('SW_FEATURE_6_HEADER')
        }
      ]
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      if (isVisible) { this.showFeatures = true }
    }
  }
}
</script>
<style lang="scss">
  @import "src/styles/Feature";
</style>
