<template>
  <v-img id="contact" :lazy-src="`/images/evening.${webpAvailable ? 'webp' : 'jpg'}`" min-height="770px" class="white--text" max-height="770px" gradient="to bottom, rgba(26, 39, 68, 0.7), rgba(26, 39, 68, 0.8)">
    <section class="px-3">
      <section-header :header="$t('SW_CONTACT')" :sub-header="$t('SW_CONTACT_SUBHEADER')" :text="$t('SW_CONTACT_HEADERTEXT')"/>

      <!-- Contact form -->
      <v-card v-observe-visibility="{callback: visibilityChanged, throttle: 100, once: true}" outlined class="pa-4 mx-auto contact-form invisible" max-width="500">
        <v-expand-transition>
          <v-form v-show="!success" name="contact" netlify="true" method="POST" netlify-honeypot="bt-field1"
                  action="thanks-contact" lazy-validation ref="form" v-model="valid">
            <!-- Netlify form name field -->
            <v-text-field class="d-none" name="form-name" value="contact"/>
            <!-- Name -->
            <v-text-field outlined v-model="form.name" name="name" prepend-inner-icon="icon-account" :rules="nameRules" :label="$t('SW_NAME')"/>
            <!-- Email -->
            <v-text-field outlined v-model="form.email" name="email" prepend-inner-icon="icon-email" :rules="emailRules" :label="$t('SW_EMAIL')"/>
            <!-- Botfield -->
            <v-text-field class="d-none" name="bt-field1"/>
            <!-- Phone -->
            <v-textarea outlined name="message" no-resize height="100px" prepend-inner-icon="icon-messages" :label="$t('SW_MESSAGE')" v-model="form.message"></v-textarea>
            <!-- Send message -->
            <v-btn color="primary" block depressed large rounded @click="submit" :loading="submitting">
              <i class="icon-send mr-1"></i>
              {{ $t('SW_SEND') }}
            </v-btn>
          </v-form>
        </v-expand-transition>

        <!-- Message submitted -->
        <v-expand-transition>
          <div v-show="success" class="text-center pa-3">
            <i class="icon-check-outline mb-2 font-36" style="color: #459b49"></i>
            <p class="title">{{ $t('SW_MESSAGE_RECEIVED') }}</p>
            <p>{{ $t('SW_CONTACT_SOON') }}</p>
          </div>
        </v-expand-transition>
      </v-card>
    </section>
  </v-img>
</template>
<script>
import Axios from 'axios'

export default {
  data () {
    return {
      valid: true,
      success: false,
      submitting: false,
      form: { email: '', name: '', message: '' },
      nameRules: [
        v => !!v || this.$i18n.t('SW_NAME_REQUIRED')
      ],
      emailRules: [
        v => !!v || this.$i18n.t('SW_EMAIL_REQUIRED')
      ]
    }
  },

  methods: {
    visibilityChanged (isVisible, entry) {
      const { target } = entry

      if (isVisible) {
        target.classList.remove('invisible')
        target.classList.add('visible')
      }
    },
    submit () {
      if (this.submitting) return
      if (!this.$refs.form.validate()) return

      this.submitting = true

      let form = document.forms.namedItem('contact')
      let formAction = form.getAttribute('action')
      let formData = new FormData(form)

      let netlify = Axios.create({
        baseURL: '/',
        timeout: 10000,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })

      netlify.post(formAction, formData)
        .then(() => {
          this.success = true
          this.form = { email: '', name: '', message: '' }
        })
        .finally(() => { this.submitting = false })
    }
  }
}
</script>

<style lang="scss">
  @import "src/styles/Contact";
</style>
