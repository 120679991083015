import 'intersection-observer'
import Vue from 'vue'
import 'plugins/components'
import vuetify from 'plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import 'styles/index.scss'
import VueMeta from 'vue-meta'
import LanguagePlugin from 'plugins/language'
import { canUseWebP } from './utils/can-use-webp'
import VueObserveVisibility from 'vue-observe-visibility'
import RouterAfterEachPlugin from 'plugins/router-after-each'

Vue.mixin({
  data: function () {
    return {
      get webpAvailable () {
        return canUseWebP()
      }
    }
  }
})

Vue.use(VueObserveVisibility)
Vue.use(LanguagePlugin)
Vue.use(VueMeta)

// Internationalization
Vue.use(LanguagePlugin)
const i18n = LanguagePlugin.geti18n()

// Init Custom routes plugins
Vue.use(RouterAfterEachPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
