import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#E90000',
        secondary: '#455A64',
        accent: '#82B1FF',
        error: '#990500',
        info: '#2196F3',
        success: '#459b49',
        warning: '#ffaa17'
      }
    }
  }
})
