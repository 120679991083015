<template>
  <v-footer class="app-footer" min-height="300px">
    <v-container class="pa-9" fluid>
      <v-row align="center" class="fill-height" justify="center">
        <div>
          <v-divider class="mt-5 mb-4 mx-auto"/>
          <p class="text-center">&copy; {{ new Date().getFullYear() }} &centerdot;
            {{ $t('SW_PRODUCT_BY') }}
            <a :href="business.url" target="_blank">{{ business.name }}</a></p>
          <p class="text-center">
            <i class="mr-2 icon-map-marker"></i>
            <a href="http://goo.gl/maps/SQlrh" rel="noopener noreferrer" target="_blank">{{ business.streetAddress }} &centerdot; {{ business.postAddress }} &centerdot; {{ business.country }}</a>
          </p>
          <p class="text-center">
            <i class="mr-2 icon-email"></i>
            <a :href="'mailto: ' + business.mail">{{ business.mail }}</a>
          </p>
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import config from 'config'
export default {
  data () {
    return {
      business: config.business
    }
  }
}
</script>

<style lang="scss">
  .app-footer .v-divider {
    border-width: 1px;
    max-width: 50px;
  }
</style>
